import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    carService as service,
    cartypeService,
    deviceService,
    modelcarService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import CarRentalIcon from '@mui/icons-material/CarRental'
import SpeedIcon from '@mui/icons-material/Speed'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import CrudFormTabs from '../../crud/CrudFormTabs'
import TabRental from './TabRental'
import TabShare from './TabShare'
import TabTelemetria from './TabTelemetria'
import TabInLive from './TabInLive'
import { selectIconCar } from '../../utils/Utils'
import KeyIcon from '@mui/icons-material/Key'
import { Button, Grid } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import RouteIcon from '@mui/icons-material/Route'
import { headerFecth } from '../../../utils/Data'

const initElement = {
    enabled: true,
    name: '',
    number: '',
    alias: '',
    brand: '',
    model: '',
    search_text: '',
    modelCar: { id: null, name: null },
    cardType: { id: null, name: null },
    device: { id: null, serial_number: null },
    current_status: '',
    rentActive: { id: null, name: null },
    shareActive: { id: null, name: null },
    nextRent: { id: null, name: null },
    statusCar: '',
    fuel_capacity: 0,
    fuel_wltp: 0,
    lat: 0,
    lng: 0,
    km: 0,
    door_status: 0,
    engine_status: 0,
    fuel_level: 0,
    speed: 0,
    partial_fuel: 0,
    partial_km: 0,
    seats: 0,
    typeChange: '',
}

const center = {
    lat: 42.8802049,
    lng: -8.5447697,
}

const containerStyle = {
    width: '100%',
    height: '400px',
}

export default function FormCar() {
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const [map, setMap] = useState(null)
    const { t, i18n } = useTranslation()

    const fieldRentalActive = (
        field,
        valueform,
        objectform,
        setSate,
        setValueField
    ) => {
        if (objectform['id'] && objectform['id'] > 0) {
            if (objectform?.rentActive) {
                return (
                    <PanelInfo>
                        <h2>Rental Activo</h2>
                        <p>
                            <b>Fecha comienzo:</b>{' '}
                            <span>{objectform?.rentActive?.start}</span>
                        </p>
                        <p>
                            <b>Fecha finalizar:</b>{' '}
                            <span>{objectform?.rentActive?.end}</span>
                        </p>
                        <p>
                            <b>Fecha real:</b>{' '}
                            <span>{objectform?.rentActive?.start_real}</span>
                        </p>
                        <p>
                            <b>Usuario Email:</b>{' '}
                            <span>{objectform?.rentActive?.user?.email}</span>
                        </p>
                        <p>
                            <b>Usuario Nombre:</b>{' '}
                            <span> {objectform?.rentActive?.user?.name} </span>
                        </p>
                        <p>
                            <b>Usuario Tlf:</b>{' '}
                            <span>{objectform?.rentActive?.user?.phone}</span>
                        </p>
                    </PanelInfo>
                )
            } else {
                return (
                    <PanelInfo>
                        <h2>Rental Activo</h2>
                        <p>
                            No se encuentra ningun rental activo en este momento
                        </p>
                    </PanelInfo>
                )
            }
        } else {
            return <></>
        }
    }

    const fieldRentalNext = (
        field,
        valueform,
        objectform,
        setSate,
        setValueField
    ) => {
        if (objectform['id'] && objectform['id'] > 0) {
            if (objectform?.rentActive) {
                return (
                    <PanelInfo>
                        <h2>Rental reservado</h2>
                        <p>
                            <b>Fecha comienzo:</b>{' '}
                            <span>{objectform?.nextRent?.start}</span>
                        </p>
                        <p>
                            <b>Fecha finalizar:</b>{' '}
                            <span>{objectform?.nextRent?.end}</span>
                        </p>
                        <p>
                            <b>Usuario Email:</b>{' '}
                            <span>{objectform?.nextRent?.user?.email}</span>
                        </p>
                        <p>
                            <b>Usuario Nombre:</b>{' '}
                            <span> {objectform?.nextRent?.user?.name} </span>
                        </p>
                        <p>
                            <b>Usuario Tlf:</b>{' '}
                            <span>{objectform?.nextRent?.user?.phone}</span>
                        </p>
                    </PanelInfo>
                )
            } else {
                return (
                    <PanelInfo>
                        <h2>Rental reservado</h2>
                        <p>
                            No se encuentra ningun rental reservado para este
                            vehiculo
                        </p>
                    </PanelInfo>
                )
            }
        } else {
            return <></>
        }
    }

    const fieldShare = (
        field,
        valueform,
        objectform,
        setSate,
        setValueField
    ) => {
        if (objectform['id'] && objectform['id'] > 0) {
            if (objectform?.shareActive) {
                return (
                    <PanelInfo>
                        <h2>Share activo</h2>
                        <p>
                            <b>Fecha comienzo:</b>{' '}
                            <span>{objectform?.shareActive?.start}</span>
                        </p>
                        <p>
                            <b>Usuario Email:</b>{' '}
                            <span>{objectform?.shareActive?.user?.email}</span>
                        </p>
                        <p>
                            <b>Usuario Nombre:</b>{' '}
                            <span> {objectform?.shareActive?.user?.name} </span>
                        </p>
                        <p>
                            <b>Usuario Tlf:</b>{' '}
                            <span>{objectform?.shareActive?.user?.phone}</span>
                        </p>
                    </PanelInfo>
                )
            } else {
                return (
                    <PanelInfo>
                        <h2>Share activo</h2>
                        <p>
                            No se encuentra ningun share activo en este vehiculo
                        </p>
                    </PanelInfo>
                )
            }
        } else {
            return <></>
        }
    }

    const fieldInfo = (
        field,
        valueform,
        objectform,
        setSate,
        setValueField
    ) => {
        if (objectform['id'] && objectform['id'] > 0) {
            return (
                <PanelInfo>
                    <h2>Información</h2>
                    <p>
                        <LocationOnIcon />
                        <b>Ubicación:</b>{' '}
                        <span>
                            {objectform?.lat != null ? objectform?.lat : ''}{' '}
                            {' , '}{' '}
                            {objectform?.lng != null ? objectform?.lng : ''}
                        </span>
                    </p>
                    <p>
                        <MeetingRoomIcon />
                        <b>Puerta:</b>{' '}
                        <span>
                            {' '}
                            {objectform?.door_status == 1
                                ? 'Cerrada'
                                : 'Abierta'}{' '}
                        </span>
                    </p>
                    <p>
                        <CarRentalIcon />
                        <b>Encendido:</b>{' '}
                        <span>
                            {objectform?.engine_status == 1 ? 'Sí' : 'No'}
                        </span>
                    </p>
                    <p>
                        <LocalGasStationIcon />
                        <b>Depositio:</b>{' '}
                        <span>
                            {objectform?.fuel_level > 0 &&
                            objectform?.fuel_capacity > 0
                                ? (
                                      (100.0 * objectform.fuel_level) /
                                      objectform.fuel_capacity
                                  ).toFixed(2) + '%'
                                : '--'}
                        </span>
                    </p>
                    <p>
                        <SpeedIcon />
                        <b>Velocidad:</b> <span>{objectform?.speed}</span>
                    </p>
                    <p>
                        <KeyIcon />
                        <b>Bloqueado:</b>{' '}
                        <span>{objectform?.blockCar == 1 ? 'Sí' : 'No'}</span>
                    </p>
                    <p>
                        <BatteryChargingFullIcon />
                        <b>Batería:</b>{' '}
                        <span>
                            {objectform?.batery
                                ? objectform!.batery!.toFixed(2) + 'V'
                                : '--'}
                        </span>
                    </p>
                    <p>
                        <RouteIcon />
                        <b>KM:</b>{' '}
                        <span>
                            {objectform?.km
                                ? objectform!.km!.toFixed(2) + 'km'
                                : '--'}
                        </span>
                    </p>
                </PanelInfo>
            )
        } else {
            return <></>
        }
    }

    const fieldMap = (field, valueform, objectform, setSate, setValueField) => {
        if (
            objectform['id'] &&
            objectform['id'] > 0 &&
            objectform?.lat &&
            objectform?.lng
        ) {
            const point = {
                lat: objectform?.lat,
                lng: objectform?.lng,
            }

            return (
                <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                    <Map
                        center={point}
                        zoom={18}
                        style={{ width: '100%', height: '400px' }}
                        styles={[
                            {
                                featureType: 'poi',
                                stylers: [{ visibility: 'off' }],
                            },
                        ]}
                    >
                        <Marker
                            position={point}
                            title={objectform.number}
                            icon={selectIconCar(objectform)!}
                        />
                    </Map>
                </APIProvider>
            )
        } else {
            return <></>
        }
    }

    const setValueModelcarData = async (
        setValueField,
        objectform,
        value,
        valueform,
        setState,
        id,
        aux,
        modelcar
    ) => {
        var newobject = { ...objectform }

        newobject = setValueField(newobject, 'modelCar', modelcar)

        newobject = setValueField(newobject, 'brand', modelcar.brand)
        newobject = setValueField(newobject, 'model', modelcar.model)
        newobject = setValueField(newobject, 'picture', modelcar.picture)
        newobject = setValueField(
            newobject,
            'pictureSecure',
            modelcar.pictureSecure
        )
        newobject = setValueField(newobject, 'cardType', modelcar.cardType)
        newobject = setValueField(
            newobject,
            'fuel_capacity',
            modelcar.fuel_capacity
        )
        newobject = setValueField(newobject, 'seats', modelcar.seats)
        newobject = setValueField(newobject, 'bateryOk', modelcar.bateryOk)
        newobject = setValueField(
            newobject,
            'bateryWarning',
            modelcar.bateryWarning
        )
        newobject = setValueField(newobject, 'bateryKO', modelcar.bateryKO)
        newobject = setValueField(newobject, 'typeChange', modelcar.typeChange)

        let valueFormAux = { ...valueform }
        valueFormAux['cardType.id'] = {
            key: modelcar.cardType.id,
            name: modelcar.cardType.name,
        }

        valueFormAux['modelCar.id'] = {
            key: modelcar.id,
            name: modelcar.name,
        }
        valueFormAux['brand'] = modelcar.brand
        valueFormAux['model'] = modelcar.model
        valueFormAux['bateryKO'] = modelcar.bateryKO
        valueFormAux['bateryOk'] = modelcar.bateryOk
        valueFormAux['bateryWarning'] = modelcar.bateryWarning
        valueFormAux['fuel_capacity'] = modelcar.fuel_capacity
        valueFormAux['seats'] = modelcar.seats
        valueFormAux['typeChange'] = {
            key: modelcar.typeChange,
            name: modelcar.typeChange,
        }
        valueFormAux['picture'] =
            process.env.REACT_APP_HOST + '/image/' + modelcar.picture
        valueFormAux['pictureSecure'] =
            process.env.REACT_APP_HOST + '/image/' + modelcar.pictureSecure

        var update = {
            objectform: newobject,
            valueform: valueFormAux,
        }

        setState((currentState) => ({ ...currentState, ...update }))

        return newobject
    }

    const setValueModelcar = async (
        setValueField,
        objectform,
        value,
        valueform,
        setSate,
        id,
        aux
    ) => {
        if (value && (value.key || value.key === 0)) {
            var newobject = objectform

            let url =
                process.env.REACT_APP_HOST + '/admin/modelcar/one/' + value.key
            const responseblog = await fetch(url, {
                method: 'GET',
                headers: headerFecth(),
            })
            const jsoncomplete = await responseblog.json()

            if (jsoncomplete && jsoncomplete.data && jsoncomplete.data) {
                var modelcar = jsoncomplete.data
                setValueModelcarData(
                    setValueField,
                    objectform,
                    value,
                    valueform,
                    setSate,
                    id,
                    aux,
                    modelcar
                )
            }

            return newobject
        } else {
            return objectform
        }
    }

    const estructureform = [
        {
            type: 'custom',
            name: 'info',
            custom: fieldInfo,
            col: 3,
        },
        {
            type: 'custom',
            name: 'rentalactive',
            custom: fieldRentalActive,
            col: 3,
        },
        {
            type: 'custom',
            name: 'nextRent',
            custom: fieldRentalNext,
            col: 3,
        },
        {
            type: 'custom',
            name: 'shareActive',
            custom: fieldShare,
            col: 3,
        },
        {
            type: 'input',
            name: 'number',
            col: 4,
        },
        {
            type: 'input',
            name: 'alias',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'modelCar.id',
            service: modelcarService.combo,
            setvalue: setValueModelcar,
            col: 4,
        },
        {
            type: 'input',
            name: 'brand',
            col: 4,
        },
        {
            type: 'input',
            name: 'model',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cardType.id',
            service: cartypeService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'seats',
            inputtype: 'number',
            step: 1,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'typeChange',
            options: [
                { key: '', name: '' },
                { key: 'MANUAL', name: 'MANUAL' },
                { key: 'AUTOMATIC', name: 'AUTOMATICO' },
            ],
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'device.id',
            service: deviceService.combo,
            fieldlabel: '.uuid',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'statusCar',
            options: [
                { key: '', name: '' },
                { key: 'FREE', name: 'FREE' },
                { key: 'BLOCK', name: 'BLOCK' },
                { key: 'RESERVED', name: 'RESERVED' },
                { key: 'INSHARE', name: 'INSHARE' },
                { key: 'INRENT', name: 'INRENT' },
            ],
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'testDoor',
            options: [
                { key: '', name: '' },
                { key: 'NONE', name: 'NO' },
                { key: 'YES', name: 'SI' },
            ],
            col: 4,
        },
        {
            type: 'input',
            name: 'fuel_capacity',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'fuel_wltp',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'km',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'bateryOk',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'input',
            name: 'bateryWarning',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'input',
            name: 'bateryKO',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'locktype',
            options: [
                { key: '', name: '' },
                { key: 'NONE', name: 'NINGUNO' },
                { key: 'RELE', name: 'RELE' },
                { key: 'RELEINVERSE', name: 'RELE INVERSO' },
                { key: 'CAN', name: 'CAN' },
            ],
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'automaticLock',
            col: 4,
        },
        {
            type: 'image',
            name: 'picture',
            col: 2,
        },
        {
            type: 'image',
            name: 'pictureSecure',
            col: 2,
        },
        {
            type: 'textarea',
            name: 'current_status',
            readonly: true,
            col: 12,
        },
        {
            type: 'custom',
            name: 'map',
            custom: fieldMap,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['number']) {
            return t('car.error.number')
        }
        if (!object['brand']) {
            return t('car.error.brand')
        }
        if (!object['model']) {
            return t('car.error.model')
        }

        return null
    }

    const tabRental = (id) => {
        return <TabRental idcar={id} />
    }

    const tabShare = (id) => {
        return <TabShare idcar={id} />
    }

    const tabTelemetria = (id) => {
        return <TabTelemetria idcar={id} />
    }

    const tabInLive = (id) => {
        return <TabInLive idcar={id} />
    }

    const closeCar = (id) => {
        service.closedoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const openCar = (id) => {
        service.opendoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const mantenaiceStartCar = (id) => {
        service.mantenaiceStart(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const mantenaiceStopCar = (id) => {
        service.mantenaiceStop(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const lockCar = (id) => {
        service.lock(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const unlockCar = (id) => {
        service.unlock(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const moreActions = (getObject, state, saveState) => {
        var object = getObject()
        var alloLock =
            id &&
            object.engine_status == 0 &&
            object.locktype &&
            (object.locktype == 'RELE' ||
                object.locktype == 'CAN' ||
                object.locktype == 'RELEINVERSE')
        var alloUnLock =
            id &&
            object.locktype &&
            (object.locktype == 'RELE' ||
                object.locktype == 'CAN' ||
                object.locktype == 'RELEINVERSE')
        return (
            <Grid container>
                {id && !alloLock && (
                    <Grid xs={12}>
                        <label>
                            El coche no se puede bloquear por que o esta en
                            marcha o no se indico el tipo de bloqueo
                        </label>
                    </Grid>
                )}

                <Grid
                    xs={12}
                    style={{ textAlign: 'right', marginBottom: '0.5em' }}
                >
                    {id && (
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                mantenaiceStartCar(id)
                            }}
                        >
                            {' '}
                            Iniciar mantnimiento
                        </Button>
                    )}
                    {id && (
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                mantenaiceStopCar(id)
                            }}
                        >
                            {' '}
                            Finalizar mantnimiento
                        </Button>
                    )}
                    {id && (
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                openCar(id)
                            }}
                        >
                            {' '}
                            Abrir puertas
                        </Button>
                    )}
                    {id && alloUnLock && (
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                unlockCar(id)
                            }}
                        >
                            {' '}
                            Desbloqeuar
                        </Button>
                    )}
                </Grid>

                <Grid
                    xs={12}
                    style={{ textAlign: 'right', marginBottom: '0.5em' }}
                >
                    {id && (
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                closeCar(id)
                            }}
                        >
                            {' '}
                            Cerrar puertas
                        </Button>
                    )}
                    {id && alloLock && (
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                lockCar(id)
                            }}
                        >
                            {' '}
                            Bloquear
                        </Button>
                    )}
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <CrudFormTabs
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'car'}
                urledit={'/car/edit/'}
                urlCancel={'/car'}
                service={service}
                recoveryElement={recoveryElement}
                moreActions={moreActions}
                tabs={[
                    { title: 'car.tab.rent', method: tabRental },
                    { title: 'car.tab.share', method: tabShare },
                    { title: 'car.tab.telemetria', method: tabTelemetria },
                    { title: 'car.tab.inlive', method: tabInLive },
                ]}
                valid={isValid}
            />
        </>
    )
}

const PanelInfo = styled.div`
    margin: 1em;
    width: 100%;
    min-height: 300px;
    background: #83c63b77;
    padding: 1em;
    border-radius: 6px;
    font-size: 0.8em;
    line-height: 1em;

    & p {
        display: flex;
        flex-direction: row;
        align-items: center;

        & * {
            margin-right: 10px;
        }
    }

    @media (max-width: 767px) {
        margin: 0.2em;
    }
`
