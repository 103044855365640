import CrudTable from '../../crud/CrudTable'
import { zoneService as service } from '../../../services'

const initFilter = {
    name: null,
}

export default function Zone() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/zone/edit/'}
            urlnew={'/zone/new/'}
            filter={initFilter}
            i18n={'zone'}
            fieldstable={['name', 'price', 'pricesDistance', 'maxKm', 'enable']}
            estructureForm={createEstructureFilter}
        />
    )
}
